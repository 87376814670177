import React from 'react';
import {section, sectionTitle} from './post-section.module.css';
import {IPostSection} from "../../../interfaces/post-section";

const PostSection = ({title, children}: IPostSection)=>{
    return (
        <section className={section}>
            <h2 className={sectionTitle}>{title}</h2>
            <p className="section-paragraphe">
                {children}
            </p>
        </section>
    );
}

export default PostSection;